/**
 * Returns a cookie value if a name is specified. Otherwise returns the entire cookies as an object
 * @param {String} name - The name of the cookie to fetch the value for. Returns the entire map of cookies if not specified
 * @returns {String|Object} - The value of the cookie specified by `name` if specified. Otherwise returns a name value map of the available cookies
 */
export function getCookie(name) {
  const cookies = document.cookie.split(';')
      .reduce((acc, cookieString) => {
          const [key, value] = cookieString.split('=').map(str => str.trim());
          if (key && value) {
              acc[key] = decodeURIComponent(value);
          }

          return acc;
      }, {});

  return name ? cookies[name] || '' : cookies;
}

/**
*
* @param {String} name - The name of the cookie to be set
* @param {String} value - The value of the cookie
* @param {Number} daysToExpire - Cookie expiration date
*/
export function setCookie(name, value, daysToExpire) {
  const date = new Date();
  date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${date.toGMTString()}; path=/`;
}
