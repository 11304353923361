import { getCookie, setCookie } from './helpers/cookie';

/**
 * @class ChoicePopup
 */
window.HMSChoicePopup = class ChoicePopup {
  constructor() {
    this.popupDisplayInterval = window.HMSModalPopupDisplayInterval || 0;
    this.cookieName = window.HMSModalPopupCookieName || 'modalPopupAccepted';
    this.cookieDuration = window.HMSModalPopupCookieDuration || 30;

    /**
     * Do not init the script if the popup template is not on the page, or if the cookie is accepted.
     */
    if (this.isOnPage() && getCookie(this.cookieName) === '') {
      this.openPopupAfterInterval();
      this.addEvents();
    }
  }

  /**
   * Checks if the popup template is on the page
   * @returns {Boolean}
   */
  isOnPage() {
    return $('#choice-popup').length !== 0;
  }

  /**
   * Add click events
   */
  addEvents() {
    $('.js-choice-accept').on('click', () => {
      setCookie(this.cookieName, true, this.cookieDuration);
      $.magnificPopup.instance.close();
    });
  }

  /**
   * Opens Popup after interval in seconds. Default interval is 0sec.
   */
  openPopupAfterInterval() {
    setTimeout(() => {
      $.magnificPopup.open({
        items: { src: '#choice-popup' },
        type: 'inline',
        closeBtnInside: false,
        closeOnBgClick: false,
        showCloseBtn: false
      });
    }, this.popupDisplayInterval * 1000);
  }
}
